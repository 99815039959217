import React from 'react';
import PropTypes from 'prop-types';
import PageError from '../pages/error/pageError';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // State reset so that the menu continues to operate
        if (this.props.location !== prevProps.location) {
            this.setState({
                error: null,
                errorInfo: null
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        if (this.state.errorInfo) {
            return <PageError backHomeUrl={this.props.backHomeUrl} />;
        }
        // If no errors, just render children
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};
