import React from 'react';
import PropTypes from 'prop-types';
import { EmptyCard } from '@weezevent/nacre';
import css from './sauronEmptyCard.module.css';

const SauronEmptyCard = ({ title, subTitle }) => {
    return <EmptyCard className={css['sauron-empty-card']} title={title} subTitle={subTitle} />;
};

SauronEmptyCard.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string
};

SauronEmptyCard.defaultProps = {
    title: '',
    subTitle: ''
};

export default SauronEmptyCard;
