import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../models/user/permissions';
import { Menu, NavMenu, NavMenuBody, NavMenuItem, Button } from '@weezevent/nacre';
import OrganizationModal from '../../../pages/organizations/OrganizationModal';
import css from './sidebar.module.css';

const SideBarMenu = () => {
    const { t } = useTranslation();
    const { capacities } = usePermissions();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            {window.weezConfig.enableCreateOrganization && (
                <>
                    <OrganizationModal open={open} onClose={() => setOpen(false)} />
                    <div className={css['create-orga-btn']}>
                        <Button fluid primary onClick={() => setOpen(true)}>
                            {t(`sauron.organizations.error.create`)}
                        </Button>
                    </div>
                </>
            )}
            <Menu useNew capacities={Array.from(capacities)}>
                <NavMenu>
                    <NavMenuItem to={'/organizations'} icon="home" exact>
                        {t('common.organizations')}
                    </NavMenuItem>
                </NavMenu>

                <NavMenu allowedCapacities={['RoleCapacity.ADMIN']}>
                    <NavMenuItem icon="users">{t('common.users')}</NavMenuItem>
                    <NavMenuBody>
                        <NavMenuItem to={'/users'} exact>
                            {t('common.users')}
                        </NavMenuItem>
                        <NavMenuItem to={'/roles'} exact>
                            {t('common.roles')}
                        </NavMenuItem>
                    </NavMenuBody>
                </NavMenu>
            </Menu>
        </>
    );
};

export default SideBarMenu;
