import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../models/user/permissions';
import { Modal, Input, InputLabel, Button } from '@weezevent/nacre';
import { Organization } from '../../models';
import css from './list.module.css';

export default function OrganizationModal({ open, onClose, sync, fromList }) {
    const { t } = useTranslation();
    const history = useHistory();
    const permissions = usePermissions();
    const [organizationName, setOrganizationName] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = React.useCallback(
        async ({ event = false }) => {
            setLoading(true);
            let organization = new Organization();
            organization.name = organizationName;
            await organization
                .save()
                .then(({ response }) => {
                    permissions.reload();
                    if (event) {
                        history.push(`ticket/O${response.id}`);
                    }
                    if (!fromList) {
                        // Redirect to root to force organization's list to refresh
                        history.push(``);
                    }
                    sync();
                    onClose();
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [organizationName, onClose, permissions, sync, fromList]
    );

    if (!open) {
        return null;
    }

    return (
        <Modal
            size="large"
            open={open}
            allowExternalClosing={false}
            onClose={onClose}
            title={t(`sauron.organizations.error.create`)}
            subtitle={t(`sauron.organizations.error.modal.subTitle`)}
            customFooter={
                <Button.Group>
                    <Button disabled={loading} onClick={onClose} label={t('common.cta.back')} />
                    {/* <Button
                        disabled={loading}
                        primary
                        inverted
                        onClick={() => handleSubmit(true)}
                        label={t('sauron.organizations.error.modal.confirm')}
                    /> */}
                    <Button disabled={loading} primary onClick={() => handleSubmit(false)} label={t('common.cta.confirm')} />
                </Button.Group>
            }
        >
            <div className={css['organization-modal-content']}>
                <InputLabel label={t('sauron.organizations.error.modal.input')} />
                <Input value={organizationName} placeholder={t('sauron.organizations.error.modal.input')} onChange={({ target: { value } }) => setOrganizationName(value)} />
            </div>
        </Modal>
    );
}

OrganizationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    sync: PropTypes.func,
    fromList: PropTypes.bool
};

OrganizationModal.defaultProps = {
    open: false,
    onClose: () => {},
    sync: () => {},
    fromList: false
};
