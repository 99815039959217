import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DropdownLight } from '@weezevent/nacre';
import css from './dropdownPageSize.module.css';

export default function DropdownPageSize({
    current,
    onChange = () => {},
    onChangeCurrentpage = () => {},
    labelItem = 'sauron.users.page_size',
    totalCount = 0,
    loading = false,
    pageSizes = [50, 100, 250, 500],
    ...rest
}) {
    const { t } = useTranslation();

    let pageSizesOptions = React.useMemo(() => {
        const pageSizeToOption = value => ({ key: value, label: t(labelItem, { count: value }), value: value });
        return pageSizes.map(pageSizeToOption);
    }, [pageSizes, labelItem]);

    if (loading || totalCount <= Math.min.apply(Math, pageSizes)) {
        return null;
    }

    return (
        <DropdownLight
            position={'bottom-end'}
            className={css['dropdown-pagesize']}
            value={current}
            onChange={value => {
                onChange(+value);
                onChangeCurrentpage(0);
            }}
            items={pageSizesOptions}
            {...rest}
        />
    );
}

DropdownPageSize.propTypes = {
    onChange: PropTypes.func,
    onChangeCurrentPage: PropTypes.func,
    current: PropTypes.number.isRequired,
    labelItem: PropTypes.string,
    totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool
};
