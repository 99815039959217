import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from '../../utils/auth/authRoute';
import OrganizationList from '../../pages/organizations/list';
import { UsersList } from '../../pages/users/list';
import { UserProfile } from '../../pages/users/user/User';

export const OrganizationRouter = ({ match }) => {
    const { path } = match;
    return (
        <Switch>
            <Route exact path={path} render={() => <OrganizationList />} />
            <AuthRoute
                exact
                allow={['RoleCapacity.OWNER']}
                path={`${path}/O:organizationId/users`}
                render={({
                    match: {
                        params: { organizationId }
                    }
                }) => <UsersList organizationId={organizationId} />}
            />
            <AuthRoute
                exact
                allow={['RoleCapacity.OWNER']}
                path={`${path}/O:organizationId/users/:userId`}
                render={({
                    match: {
                        params: { organizationId, userId }
                    }
                }) => <UserProfile organizationId={organizationId} userId={userId} />}
            />
        </Switch>
    );
};
