import React from 'react';
import { Modal, Button, SelectHierarchyLabel, ToastContext } from '@weezevent/nacre';
import { useForm } from '@weezevent/weezjs-core';
import { Organization, Role } from '../../../../models';
import { LoadingOptions } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../models/user/permissions';

const DEFAULT_PARAMS = { search: '', limit: 10, offset: 0 };
const DEFAULT_FORM = {
    product: 'default',
    role: null,
    organization: 'default'
};

const RoleCreationModal = ({ open, organizationId = null, user, onClose, sync }) => {
    const { t } = useTranslation();
    const _user = usePermissions();
    const toast = React.useContext(ToastContext);
    const [organizationsParams, setOrganizationsParams] = React.useState(DEFAULT_PARAMS);
    const [searchRole, setSearchRole] = React.useState('');

    const [form, setForm, setFormObject] = useForm({ ...DEFAULT_FORM });

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            query: organizationsParams,
            launch: open,
            onSuccess: () => {
                if (organizationId) {
                    setForm('organization', Number(organizationId));
                }
            }
        },
        [organizationsParams, open, organizationId]
    );

    const [organization, loadingOrganization] = Organization.useApiModel(
        {
            id: organizationId,
            launch: open && Boolean(organizationId)
        },
        [open, organizationId]
    );

    const [roles, loadingRoles] = Role.useApiModel(
        {
            organizationId,
            cache: false,
            allow_cache: false,
            query: {
                search: searchRole
            },
            launch: open
        },
        [organizationId, open, searchRole]
    );

    const organizationsOptions = React.useMemo(() => {
        switch (true) {
            case !organizationId && loadingOrganizations:
            case Boolean(organizationId) && loadingOrganization:
                return LoadingOptions;
            case Boolean(organizationId) && !loadingOrganization && !organization:
            case !organizationId && !loadingOrganizations && !organizations:
                return [];
            case Boolean(organizationId):
                return [organization.toOption()];
            case organizationsParams.search:
                return organizations.map(org => org.toOption());
            default:
                return [{ key: 0, value: 'default', text: t('common.all') }, ...organizations?.map(org => org.toOption())];
        }
    }, [organization, organizations, loadingOrganization, loadingOrganizations, LoadingOptions, organizationId]);

    const rolesOptions = React.useMemo(() => {
        switch (true) {
            case loadingRoles:
                return LoadingOptions;
            case !loadingRoles && !roles:
                return [];
            default:
                return roles.map(role => role.toOption());
        }
    }, [roles, loadingRoles, LoadingOptions]);

    // No need for callbacks here
    const handleSearch = value => {
        if (!value) {
            setOrganizationsParams(DEFAULT_PARAMS);
        }
        setOrganizationsParams({ ...DEFAULT_PARAMS, search: value });
    };

    const handleSubmit = React.useCallback(
        (multi = false) => {
            const _role = roles.find(role => role.slug === form.role);
            const { username } = user;
            let datas = {
                context: form.organization === 'default' ? [] : ['*', '*', `${form.organization}`],
                user: {
                    username
                }
            };

            if (form.organization !== 'default') {
                datas.context['value'] = form.organization;
            }
            let role = new Role({ ...datas, pk: _role.slug });
            role.save({
                slug: _role.slug,
                organizationId
            })
                .then(response => {
                    if (response.status === 201) {
                        toast.success(t('sauron.toasts.roles.add-role-success'));
                    }
                    sync();
                    setFormObject({ ...DEFAULT_FORM }, true);
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    if (!multi) {
                        onClose();
                    }
                });
        },
        [form, roles, user, sync, onClose]
    );

    return (
        <Modal
            size={'large'}
            open={open}
            allowExternalClosing={false}
            onClose={onClose}
            title={t('sauron.user.add-role')}
            subtitle={t('sauron.user.add-role-subtitle')}
            customFooter={
                <Button.Group>
                    <Button onClick={onClose} label={t('common.cta.cancel')} />
                    <Button primary inverted onClick={() => handleSubmit(true)} disabled={!form.role} label={t('sauron.user.add-role-action')} />
                    <Button primary onClick={() => handleSubmit(false)} disabled={!form.role} label={t('common.cta.save')} />
                </Button.Group>
            }
        >
            <>
                <SelectHierarchyLabel
                    searchable
                    value={form.role}
                    options={rolesOptions}
                    externalOptions={rolesOptions}
                    onChange={value => setForm('role', value)}
                    label={t('common.role')}
                    placeholder={t('common.select')}
                    placeholderSearch={t('common.search')}
                    searchValue={searchRole}
                    onExternalSearch={value => setSearchRole(value)}
                />
                <SelectHierarchyLabel
                    searchable
                    value={form.organization}
                    options={organizationsOptions}
                    externalOptions={organizationsOptions}
                    disabled={organizationId && !_user.isAdmin()}
                    onChange={value => setForm('organization', value)}
                    label={t('common.organization')}
                    placeholder={t('common.select')}
                    placeholderSearch={t('common.search')}
                    searchValue={organizationsParams.search}
                    onExternalSearch={value => handleSearch(value)}
                />
            </>
        </Modal>
    );
};

export default RoleCreationModal;
