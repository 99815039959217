import React from 'react';

import { Dimmer } from 'semantic-ui-react';
import { WeezAnimation } from '../images';

export default function Loading() {
    return (
        <Dimmer active inverted style={{ height: '100vh' }}>
            <img alt="Loading Weezevent" style={{ maxWidth: '100px' }} src={WeezAnimation} />
        </Dimmer>
    );
}
