import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.js';
import { fetcher } from '@weezevent/weezjs-core';
import { ToastProvider } from '@weezevent/nacre';
import { Authentication } from './models/user/authentication.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PermissionsLayer } from './models/user/permissions.js';
import AppContextProvider from './context/appContextProvider.js';

import { Loading, ErrorBoundary } from './components';
import './styles/main.scss';

function Sauron() {
    fetcher.initialize(window.weezConfig.api.apiUrl);

    return (
        <React.Suspense fallback={<Loading />}>
            <ToastProvider>
                <Router>
                    <Authentication config={window.weezConfig.connect} redirectPath="/">
                        <PermissionsLayer>
                            <AppContextProvider>
                                <Route path="/">
                                    <ErrorBoundary location={window.location}>
                                        <App />
                                    </ErrorBoundary>
                                </Route>
                            </AppContextProvider>
                        </PermissionsLayer>
                    </Authentication>
                </Router>
            </ToastProvider>
        </React.Suspense>
    );
}

ReactDOM.render(<Sauron />, document.getElementById('main'));
