import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from '../../utils/auth/authRoute';
import { RolesList } from '../../pages/roles/list';
import { RoleCapacities } from '../../pages/roles/role/Capacities';

export const RolesRouter = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <AuthRoute allow={['RoleCapacity.ADMIN']} exact path={path} render={() => <RolesList />} />
            <AuthRoute
                allow={['RoleCapacity.ADMIN']}
                exact
                path={`${path}/:roleSlug/capacities`}
                render={({
                    match: {
                        params: { roleSlug }
                    }
                }) => <RoleCapacities roleId={roleSlug} />}
            />
        </Switch>
    );
};
