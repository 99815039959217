import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxLabel } from '@weezevent/nacre';
import css from './filters.module.css';

const QuickFilters = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className={css['quick-filters-container']}>
            <span className={css['quick-filters-label']}>{t('sauron.capacities.quick-filter')}</span>
            {React.Children.toArray(children).map(child => child)}
        </div>
    );
};

const Filter = ({ active, label, onToggle }) => {
    return (
        <div className={css['quick-filters']}>
            <CheckboxLabel label={label} checked={active} onChange={({ target: { checked } }) => onToggle(checked)} />
        </div>
    );
};

Filter.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onToggle: PropTypes.func
};

Filter.defaultProps = {
    active: false,
    onToggle: () => {}
};

QuickFilters.Item = Filter;
export default QuickFilters;
