import React from 'react';

import { TopBar } from '@weezevent/nacre';
import Account from './account';

export default function TopBarModules() {
    return (
        <TopBar.Modules>
            <Account />
        </TopBar.Modules>
    );
}
