import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, InfoText } from '@weezevent/nacre';
import css from './infoTextTooltip.module.css';

function TooltipContent({ title, subtitle = null }) {
    return (
        <span className={css['infotext-tooltip__span-tooltip']}>
            <div>{title}</div>
            {subtitle && <div>{subtitle}</div>}
        </span>
    );
}

export default function InfoTextTooltip({ title, subtitle = null, className = '' }) {
    return (
        <InfoText className={`${css['infotext-tooltip']} ${className}`} subtitle={subtitle}>
            <Tooltip triggerOnHover position={'top-start'} title={<TooltipContent title={title} subtitle={subtitle} />} margin={false}>
                <span>{title}</span>
            </Tooltip>
        </InfoText>
    );
}

InfoTextTooltip.propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    className: PropTypes.string
};
