import React from 'react';
import PropTypes from 'prop-types';
import { usePermissions } from '../../models/user/permissions';
import { UserAccess } from '@weezevent/nacre';

export default function Auth({ allow, children, fallback = null }) {
    const permissions = usePermissions();
    return <UserAccess allow={allow} capacities={Array.from(permissions.capacities)} render={isAllowed => (isAllowed ? children : fallback)} />;
}

Auth.propTypes = {
    allow: PropTypes.array,
    fallback: PropTypes.any
};
