import React from 'react';
import PropTypes from 'prop-types';
import { Card, HeaderCard } from '@weezevent/nacre';

export const UserSideBlock = ({ user, loading }) => {
    return (
        <Card>
            <HeaderCard separator={false}></HeaderCard>
        </Card>
    );
};

UserSideBlock.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool
};

UserSideBlock.defaultProps = {
    loading: true,
    user: {}
};
