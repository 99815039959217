import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Role as RoleModel } from '../../models';
import { WeezHeader, Icon, Card, Button, ContentCard, FilterSetController, Table, TableCellDropdown, Dropdown, ToastContext } from '@weezevent/nacre';
import { listSelection, requestCount } from '../../utils/utils';
import { LoadingTable, SauronEmptyCard } from '../../components';
import RoleActions from './role/actions/ActionsHandler';

export const RolesList = () => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);
    const [actionDatas, setActionDatas] = React.useState({ action: null, item: null, name: null });
    const [search, setSearch] = React.useState('');
    const [selectedRoles, setSelectedRoles] = React.useState([]);

    const [roles, loadingRoles, syncRoles] = RoleModel.useApiModel(
        {
            query: {
                ordering: 'name',
                search,
                limit: 1000,
                offset: 0
            },
            cache: false,
            allow_cache: false,
            onError: () => {
                toast.error(t('sauron.toasts.error'));
            }
        },
        [search]
    );

    const usersCount = React.useMemo(() => {
        return requestCount(loadingRoles, roles);
    }, [roles, loadingRoles, requestCount]);

    const rolesIds = React.useMemo(() => {
        if (!roles || loadingRoles) {
            return [];
        }
        return roles.map(role => role.slug);
    }, [roles, loadingRoles]);

    const handleSelect = React.useCallback(
        role => {
            setSelectedRoles(listSelection(selectedRoles, rolesIds, role));
        },
        [selectedRoles, rolesIds, setSelectedRoles]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    <Button
                        key={0}
                        primary
                        icon={<Icon name={'plus'} />}
                        label={t('sauron.roles.add')}
                        onClick={() => setActionDatas({ action: 'add', item: null, name: 'roles' })}
                    />
                ]}
                title={t('common.roles')}
            >
                {t('sauron.roles.list')}
            </WeezHeader>

            <Card>
                <ContentCard>
                    <FilterSetController
                        total={usersCount}
                        labelTotal={t('sauron.users.role', { count: usersCount })}
                        onChange={() => {}}
                        search={{
                            onChange: (_, value) => {
                                setSearch(value);
                            },
                            handleReset: () => {
                                setSearch('');
                            },
                            placeholder: t('common.search'),
                            value: search
                        }}
                    />
                </ContentCard>
                <RoleActions datas={actionDatas} onClose={() => setActionDatas({ action: null, role: null, name: null })} sync={syncRoles} />
                <Table useNew selectable onSelectAll={() => handleSelect()}>
                    <Table.THead>
                        <Table.Tr isHeader active={rolesIds.length > 0 && rolesIds.every(id => selectedRoles.includes(id))}>
                            <th>{t(`common.role`)}</th>
                            <th>{t(`common.actions`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingRoles &&
                            roles?.length > 0 &&
                            roles.map(role => <RoleItem key={role.slug} role={role} selected={selectedRoles} onSelect={handleSelect} onOpen={setActionDatas} />)}
                    </Table.TBody>
                </Table>

                <LoadingTable loading={loadingRoles} height="30vh" />
                {!loadingRoles && !roles?.length && <SauronEmptyCard title={t('sauron.roles.empty-card-title')} subTitle={t('sauron.roles.empty-card-subtitle')} />}
            </Card>
        </>
    );
};

const RoleItem = ({ role, selected, onSelect, onOpen }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleEdit = React.useCallback(() => {
        return history.push(`roles/${role.slug}/capacities`);
    }, [role]);

    return (
        <Table.Tr onSelected={() => onSelect(role.slug)} active={selected.includes(role.slug)} onClick={handleEdit}>
            <Table.Td>{role.name}</Table.Td>
            <TableCellDropdown>
                <Dropdown.Item item={t('sauron.roles.edit')} onClick={() => onOpen({ action: 'edit', name: 'roles', item: role })} />
                <Dropdown.Item item={t('sauron.roles.manage')} onClick={handleEdit} />
                <Dropdown.Item item={t('sauron.roles.duplicate')} onClick={() => onOpen({ action: 'duplicate', name: 'roles', item: role })} />
                <Dropdown.Line />
                <Dropdown.Item item={t('common.delete')} deleteItem disabled onClick={() => onOpen({ action: 'delete', role })} />
            </TableCellDropdown>
        </Table.Tr>
    );
};
