export const businessEntities = {
    '7f2e3668-e2cb-42e8-8cf9-ce6f5f37899f': {
        countryCode: 'fr',
        phone: '+33 (0)1 86 65 24 00',
        emergencyPhone: '+33 (0)1 86 65 24 24',
        mail: 'contact@weezevent.com'
    },
    '0e66a288-f3d4-4ddb-a45e-c3c7a3906d2e': {
        countryCode: 'be',
        phone: '+32 3 808 20 56',
        emergencyPhone: '+33 (0)1 86 65 24 24',
        mail: 'contact@weezevent.com'
    },
    '1a78b6e2-9bb0-463a-8f91-1135e4f5dff5': {
        countryCode: 'en',
        phone: '+44 20 3868 7483',
        mail: 'uk@weezevent.com'
    },
    '375b5a03-5a12-47c1-897e-8ae24fb8bf0c': {
        countryCode: 'es',
        phone: '+34 911 87 81 86',
        mail: 'contacto@weezevent.com'
    },
    '5be16b35-27e1-4256-b907-44627a2b2f33': {
        countryCode: 'ch',
        phone: '+41 21 588 04 60',
        emergencyPhone: '+33 (0)1 86 65 24 24',
        mail: 'ch@weezevent.com'
    },
    'ac3cb3d0-2d4b-467c-92d7-669314dc0ab0': {
        countryCode: 'ca',
        phone: '+33 (0)1 86 65 24 00',
        emergencyPhone: '+33 (0)1 86 65 24 24',
        mail: 'info@weezevent.com'
    }
};
