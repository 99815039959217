import { BaseAPIModel } from '@weezevent/weezjs-core';

export class Role extends BaseAPIModel {
    fields() {
        return {
            name: '',
            id: { primaryKey: true, readOnly: true }
        };
    }

    toOption() {
        return {
            text: this.name || '-',
            value: this.slug,
            key: this.slug
        };
    }

    static listUrl(params) {
        if (params.slug && !params.organizationId) {
            return `/ticket/roles/${params.slug}/users/`;
        }
        if (params.slug && params.organizationId) {
            return `/ticket/organizations/${params.organizationId}/roles/${params.slug}/users/`;
        }
        if (!params.slug && params.organizationId) {
            return `/ticket/organizations/${params.organizationId}/roles/`;
        }
        return `/ticket/roles/`;
    }

    static getItemUrl(params) {
        return `/ticket/roles/${params.id}/`;
    }
}
