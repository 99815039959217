export class Context {
    constructor({ level, value, parents }) {
        this.level = level;
        this.value = value;
        this.parents = parents;
    }

    initial_values() {
        return this;
    }

    get values() {
        if (this.level === 0) {
            return [];
        }
        return [...this.parents.map(p => p.value), this.value];
    }
}
