import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePermissions } from '../../models/user/permissions';

import { Dropdown, Icon, Card, StopPropagation } from '@weezevent/nacre';
import { WeezTicketLogo, WeezAccessLogo, WeezPayLogo, WeezTargetLogo } from '../../images';

import Style from './list.module.css';

const ORIGINS = ['http://localhost', 'https://admin.uat'];

const OrganizationCard = React.memo(({ organization }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const user = usePermissions();
    const { name, id } = organization;

    const organizationName = React.useMemo(() => {
        if (!name) {
            return t('sauron.organizations.organization_without_name');
        }
        return name;
    }, [name]);

    return (
        <Card key={id} className={Style['organization-card']}>
            <div className={Style['organization-header']}>
                <div>
                    <div className={Style['organization-id-container']}>
                        <Icon name="institution" />
                        <div className={Style['organization-id']}>O {id}</div>
                    </div>
                    <div className={Style['organization-name']}>{organizationName}</div>
                </div>
                <div className={Style['organization-dropdown']}>
                    <StopPropagation>
                        <Dropdown
                            items={[
                                <Dropdown.Item
                                    key={0}
                                    item={t('sauron.organizations.see-users')}
                                    onClick={() => history.push(`organizations/O${id}/users`)}
                                    disabled={!user.canSeeUsers(id)}
                                />
                            ]}
                        />
                    </StopPropagation>
                </div>
            </div>
            <div className={Style['organization-actions']}>
                <div className={Style['organization-bo-items']}>
                    <BoItem logo={WeezTicketLogo} orgaId={id} name="ticket" primaryColor={'#F5F7FF'} />
                    <BoItem logo={WeezAccessLogo} orgaId={id} name="access" primaryColor={'#F4FDFC'} />
                    <BoItem logo={WeezPayLogo} orgaId={id} name="pay" primaryColor={'#FFFEF5'} />
                    <BoItem logo={WeezTargetLogo} orgaId={id} name="target" primaryColor={'#FFF5F6'} />
                </div>
            </div>
        </Card>
    );
});

export default OrganizationCard;

const BoItem = React.memo(({ logo, name, orgaId, primaryColor }) => {
    const { t } = useTranslation();

    const redirectUri = React.useMemo(() => {
        const url = window.location.origin;
        const path = name === 'pay' ? `${name}/#!/${orgaId}` : `${name}/O${orgaId}`;

        switch (true) {
            case ORIGINS.some(orig => url.startsWith(orig)):
                // If we are on localhost or uat, it's unsure the product is hosted so let's redirect to staging.
                return `https://admin.weezevent.be/${path}`;
            default:
                return `${url}/${path}`;
        }
    }, [name, orgaId]);

    return (
        <a href={redirectUri} className={Style['bo-item']} style={{ backgroundColor: primaryColor }}>
            <img src={logo} className={Style['bo-item-logo']} alt={'logo'} />
            <div className={Style['bo-item-text']}>{t(`sauron.organizations.${name}-bo`)}</div>
        </a>
    );
});
