import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '@weezevent/connect';
import { Organization } from '../../models';
import { requestCount } from '../../utils/utils';
import { FilterSetController, Card, ContentCard, ToastContext } from '@weezevent/nacre';

import { Paginator, SauronEmptyCard, LoadingCard } from '../../components';
import OrganizationCard from './OrganizationCard';

import Style from './list.module.css';

export default function OrganizationList() {
    const { t } = useTranslation();
    const { firstName } = useAccount();
    const toast = React.useContext(ToastContext);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [defaultPagination] = React.useState(() => ({ limit: 10, offset: 0 }));
    const [queryParams, setQueryParams] = React.useState({ search: '', ...defaultPagination });

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            query: queryParams,
            onError: () => {
                toast.error(t('sauron.toasts.error'));
            }
        },
        [queryParams]
    );

    let handleSearch = React.useCallback(
        (_, value) => {
            setQueryParams({ search: value, ...defaultPagination });
        },
        [defaultPagination]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );

    const organizationsCount = React.useMemo(() => {
        return requestCount(loadingOrganizations, organizations);
    }, [organizations, loadingOrganizations, requestCount]);

    return (
        <>
            <div>
                <div className={Style['header-title']}>
                    {t('sauron.organizations.header-title')}
                    <span className={Style['header-user-name']}>{firstName}</span>
                </div>
                <div className={Style['header-subtitle']}>{t('sauron.organizations.header-subtitle')}</div>
            </div>
            <Card>
                <ContentCard>
                    <FilterSetController
                        total={organizationsCount}
                        labelTotal={t('sauron.organizations.organization', { count: organizationsCount })}
                        onChange={() => {}}
                        search={{
                            onChange: (_, value) => {
                                handleSearch(_, value);
                            },
                            handleReset: () => {
                                handleSearch('');
                            },
                            placeholder: t('sauron.organizations.search'),
                            value: queryParams.search
                        }}
                    />
                </ContentCard>
            </Card>
            <LoadingCard loading={loadingOrganizations}>
                {organizations?.length > 0 ? (
                    <>
                        {organizations.map(organization => (
                            <OrganizationCard key={organization.id} organization={organization} />
                        ))}
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={organizationsCount} />
                    </>
                ) : (
                    <SauronEmptyCard title={t('sauron.organizations.empty-card-title')} subTitle={t('sauron.organizations.empty-card-subtitle')} />
                )}
            </LoadingCard>
        </>
    );
}
