import { BaseAPIModel } from '@weezevent/weezjs-core';
import { resolve } from '../utils/utils';

export class Capacity extends BaseAPIModel {
    fields() {
        return {
            name: {},
            slug: { primaryKey: true, readOnly: true }
        };
    }

    toOption() {
        return {
            text: resolve(this.name, this.slug),
            value: this.slug,
            key: this.slug
        };
    }

    static listUrl() {
        return `/ticket/capacities/`;
    }

    static getItemUrl({ id }) {
        return `/ticket/capacities/${id}/`;
    }
}
