import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

use(intervalPlural)
    .use(LanguageDetector)
    .use({
        // custom loading backend to avoid cache problems by exploiting create-react-app bundle hashing.
        // uses webpack dynamic imports https://webpack.js.org/api/module-methods/#import-1
        type: 'backend',
        read(language, namespace, callback) {
            import(
                /* webpackChunkName: "translations" */
                /* webpackMode: "lazy" */
                `../locales/${language}/${namespace}.json`
            ).then(
                res => {
                    callback(null, res.default);
                },
                err => {
                    callback(err, null);
                }
            );
        }
    })
    .use(initReactI18next)
    .init({
        load: 'currentOnly',
        fallbackLng: {
            'en-CA': ['en-US', 'en-GB'],
            'en-US': ['en-GB'],
            'es-ES': ['en-GB'],
            'fr-CA': ['fr-FR', 'en-GB'],
            'fr-CH': ['fr-FR', 'en-GB'],
            'fr-FR': ['en-GB'],
            'it-IT': ['en-GB'],
            'de-CH': ['de-DE', 'en-GB'],
            'de-DE': ['de-CH', 'en-GB'],
            'nl-BE': ['nl-NL', 'en-GB'],
            'nl-NL': ['nl-BE', 'en-GB'],
            'ca-ES': ['es-ES', 'en-GB'],
            br: ['fr-FR', 'en-GB'],
            eu: ['fr-FR', 'en-GB'],
            ru_RU: ['en-GB'],
            pt_PT: ['en-GB'],
            el_GR: ['en-GB'],
            tr_TR: ['en-GB'],
            default: ['en-GB']
        },
        ns: 'index',
        defaultNS: 'index',
        interpolation: {
            escapeValue: false,
            prefix: '%',
            suffix: '%'
        },
        react: {
            useSuspense: true,
            transSupportBasicHtmlNodes: true
        },
        debug: process.env.NODE_ENV !== 'production'
    });
export const aliases = {
    'FR-fr': 'fr-FR',
    fr: 'fr-FR',
    en: 'en-GB',
    es: 'es-ES',
    it: 'it-IT'
};

window.i18n = i18n;
export default i18n;
