import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Auth from './auth';

export default function AuthRoute({ allow = [], fallback, isAdmin = true, ...routeProps }) {
    if (isAdmin) allow.push('PyvarCapacity.ADMIN');
    return (
        <Auth allow={allow} fallback={fallback}>
            <Route {...routeProps} />
        </Auth>
    );
}

AuthRoute.propTypes = {
    allow: PropTypes.array,
    fallback: PropTypes.any,
    isAdmin: PropTypes.bool
};
