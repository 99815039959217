import { BaseAPIModel } from '@weezevent/weezjs-core';

export class User extends BaseAPIModel {
    fields() {
        return {
            username: { primaryKey: true, readOnly: true },
            name: {}
        };
    }

    getEmail() {
        return this.email || '';
    }

    static listUrl({ organizationId }) {
        if (!organizationId) {
            return `/ticket/users`;
        }
        return `/ticket/organizations/${organizationId}/users`;
    }
}
