import React from 'react';
import PropTypes from 'prop-types';
import { Paginator as NacrePaginator } from '@weezevent/nacre';
import css from './paginator.module.css';

const Paginator = ({ currentPage, onPageChange, pageSize, totalCount }) => {
    return (
        <div className={css['paginator-container']}>
            <NacrePaginator currentPage={currentPage} onPageChange={onPageChange} pageSize={pageSize} totalCount={totalCount} />
        </div>
    );
};

Paginator.propTypes = {
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
    pageSize: PropTypes.number,
    totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Paginator.defaultProps = {
    currentPage: 1,
    onPageChange: () => {},
    pageSize: 10,
    totalCount: '--'
};

export default Paginator;
