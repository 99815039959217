import React from 'react';
import { useTranslation } from 'react-i18next';
import { fetcher, useForm } from '@weezevent/weezjs-core';
import { Role as RoleModel, Capacity } from '../../../../models';
import { Input, InputLabel, TextAreaLabel, Message, ToastContext, ToggleContainer } from '@weezevent/nacre';

import { RoleActionModal } from './ActionsHandler';

import css from '../../roles.module.css';

export const EditCapacity = ({ action, name, item, onClose, sync }) => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);

    const [form, setForm] = useForm({
        name: item.name || '',
        slug: item.slug || ''
    });

    const handleSubmit = React.useCallback(() => {
        let _capacity = new Capacity({ ...form });

        _capacity
            .save()
            .then(response => {
                if (response.status === 200) {
                    toast.success(t(`sauron.capacity.toast-edit-success`));
                }
            })
            .catch(() => {
                toast.error(t('sauron.toasts.error'));
            })
            .finally(() => {
                sync();
                onClose();
            });
    }, [form, sync, onClose]);

    return (
        <RoleActionModal action={action} name={name} item={item} onClose={onClose} onSubmit={handleSubmit} disabled={false}>
            <div className={css['role-action-input']}>
                <InputLabel label={t('sauron.capacity.slug')} />
                <Input
                    value={form.slug}
                    disabled
                    placeholder={t('sauron.capacity.slug')}
                    onChange={({ target: { value } }) => {
                        setForm('slug', value);
                    }}
                />
            </div>
            <div className={css['role-action-input']}>
                <InputLabel label={t('sauron.capacity.name')} />
                <Input
                    value={form.name}
                    placeholder={t('sauron.capacity.name')}
                    onChange={({ target: { value } }) => {
                        setForm('name', value);
                    }}
                />
            </div>
        </RoleActionModal>
    );
};

export const EditCreateOrDuplicate = ({ action, name, item, onClose, sync }) => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);

    const [defaultRole] = React.useState(() => ({
        name: item ? (action === 'duplicate' ? `${item.name} - copy` : item.name) : '',
        description: item?.description || '',
        slug: item ? (action === 'duplicate' ? '' : item.slug) : '',
        capacities: item?.capacities || [],
        saveCapacities: true
    }));

    const [form, setForm] = useForm(defaultRole);

    const disabled = React.useMemo(() => {
        return !form.name || !form.slug;
    }, [form]);

    const handleSubmit = React.useCallback(() => {
        let _role = new RoleModel({ ...form });

        if (action === 'edit') {
            // BaseApiModel need an id to patch, but Role model only have a slug
            _role.id = item.slug;
        }

        if (!form.saveCapacities) {
            _role.capacities = [];
        }

        _role
            .save()
            .then(response => {
                if (response.status === 201) {
                    toast.success(t(`sauron.roles.${action}-success`));
                }
            })
            .catch(({ response }) => {
                const slugError = Object.keys(response).includes('slug');
                toast.error(slugError ? t('sauron.roles.add-error') : t('sauron.toasts.error'));
            })
            .finally(() => {
                sync();
                onClose();
            });
    }, [form, sync, onClose, action, item]);

    return (
        <RoleActionModal action={action} name={name} item={item} onClose={onClose} onSubmit={handleSubmit} disabled={disabled}>
            <div className={css['role-action-input']}>
                <InputLabel label={t('sauron.roles.role-name')} />
                <Input
                    value={form.name}
                    placeholder={t('sauron.roles.role-name')}
                    onChange={({ target: { value } }) => {
                        setForm('name', value);
                    }}
                />
            </div>
            <div className={css['role-action-input']}>
                <InputLabel label={t('sauron.roles.role-slug')} />
                <Input
                    value={form.slug}
                    disabled={action === 'edit'}
                    placeholder={t('sauron.roles.role-slug')}
                    onChange={({ target: { value } }) => {
                        setForm('slug', value);
                    }}
                />
            </div>
            <TextAreaLabel
                className={css['role-action-textarea']}
                label={t('sauron.roles.role-description')}
                value={form.description}
                onChange={({ target: { value } }) => {
                    setForm('description', value);
                }}
                maxlength={300}
                placeholder={t('sauron.roles.role-description-placeholder')}
            />
            {name === 'duplicate' && (
                <ToggleContainer
                    mini
                    toggle={form.saveCapacities}
                    title={t('sauron.roles.duplicate-tooltip-label')}
                    helpLabel={t('sauron.roles.duplicate-tooltip-message')}
                    onToggle={checked => setForm('saveCapacities', checked)}
                />
            )}
        </RoleActionModal>
    );
};

export const DeleteAction = ({ action, name, item, onClose, sync }) => {
    const { t } = useTranslation();
    const [userRoles, setUserRoles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const disabled = React.useMemo(() => {
        return userRoles.length > 0;
    }, [userRoles]);

    const handleSubmit = React.useCallback(() => {
        return; // Waiting for further backend implementation / securization
        // item.delete({ id: item.pk }).then(() => {
        //     sync();
        // });
    }, [item, sync]);

    React.useEffect(() => {
        setLoading(true);
        fetcher.get(item.user_roles).then(({ response }) => {
            setUserRoles(response);
            setLoading(false);
        });
        // Prevent memory leaks
        return () => {
            setLoading(false);
        };
    }, []);

    return (
        <RoleActionModal
            action={action}
            name={name}
            item={item}
            onClose={onClose}
            saveLabel={t('common.cta.confirm')}
            disabled={disabled}
            loading={loading}
            onSubmit={handleSubmit}
        >
            {userRoles.length > 0 && (
                <Message title={t('sauron.roles.delete-message-label')} type="warning">
                    {t('sauron.roles.delete-message-content')}
                </Message>
            )}
        </RoleActionModal>
    );
};
