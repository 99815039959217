import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { logoError } from '../../images';
import { useHistory } from 'react-router-dom';
import { ErrorCard } from '@weezevent/nacre';
import { businessEntities } from './BusinessEntities';
import css from './errorCard.module.css';

export default function PageError({ errorCode = null, title = null, subTitle = null, refresh = true, backHomeUrl = null, contactSupport = true }) {
    const { t } = useTranslation();
    const history = useHistory();

    const hrefContactSupport = React.useMemo(() => {
        if (errorCode === 404 || contactSupport === false) {
            return null;
        }

        return `mailto:${Object.values(businessEntities)[0].mail}`;
    }, [errorCode, contactSupport]);

    const _title = React.useMemo(() => {
        if (title) {
            return t(title);
        }
        if (errorCode === 403) {
            return t('common.error.labelForbidden');
        }
        if (errorCode === 404) {
            return t('common.error.labelNotFound');
        }

        return t('common.error.titleClientError');
    }, [title, errorCode]);

    const _subTitle = React.useMemo(() => {
        if (subTitle) {
            return t(subTitle);
        }
        if (errorCode === 403 || errorCode === 404) {
            return t('common.error.errorCodeLabel', { code: errorCode });
        }

        return t('common.error.labelPleaseRefresh');
    }, [subTitle, errorCode]);

    const onRefresh = React.useCallback(() => {
        window.location.reload(false);

        if (errorCode === 403) {
            localStorage.removeItem('weezeventAuthToken');
        }
    }, [errorCode, refresh]);

    const onBackHome = React.useCallback(() => {
        history.push(backHomeUrl);
    }, [history, backHomeUrl]);

    return (
        <ErrorCard
            className={css['sauron-error-card']}
            image={logoError}
            title={_title}
            subTitle={_subTitle}
            onRefresh={!refresh || errorCode === 404 ? null : onRefresh}
            onBackHome={backHomeUrl ? onBackHome : null}
            hrefContact={hrefContactSupport}
            labelContact={t('common.error.labelContactSupport')}
            labelLinkContact={t('common.error.labelLinkContactSupport')}
            labelRefresh={t('common.cta.refresh')}
            labelBackHome={t('common.cta.backHome')}
        />
    );
}

PageError.propTypes = {
    errorCode: PropTypes.number,
    title: PropTypes.node,
    subTitle: PropTypes.node,
    refresh: PropTypes.bool,
    backHomeUrl: PropTypes.string,
    backHomeOldBo: PropTypes.bool,
    contactSupport: PropTypes.bool
};
