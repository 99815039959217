import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { OrganizationRouter, RolesRouter, UsersRouter } from './components/routing';
import { Template } from './components/template/Template';
import PageError from './pages/error/pageError';
import { Loading } from './components';

/**
 * @function account
 * @description Display the instanciation of the DropdownAccount
 * @return {ReactElement} - Component to document
 */
export default function App() {
    return (
        <React.Suspense fallback={<Loading />}>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        let url = window.location.pathname;
                        let redirect = url;

                        if (url === '/') {
                            redirect = `${url}organizations`;
                        }
                        if (url === '/target/') {
                            redirect = '/organizations';
                        }
                        return <Redirect to={redirect} />;
                    }}
                />
                <Route
                    path="/organizations"
                    render={basenameProps => {
                        return (
                            <Template>
                                <OrganizationRouter match={basenameProps.match} />
                            </Template>
                        );
                    }}
                />
                <Route
                    path="/users"
                    render={basenameProps => {
                        return (
                            <Template>
                                <UsersRouter match={basenameProps.match} />
                            </Template>
                        );
                    }}
                />
                <Route
                    path="/roles"
                    render={basenameProps => {
                        return (
                            <Template>
                                <RolesRouter match={basenameProps.match} />
                            </Template>
                        );
                    }}
                />
                <Route
                    render={() => (
                        <Template>
                            <PageError errorCode={404} backHomeUrl={'/organizations'} />
                        </Template>
                    )}
                />
            </Switch>
        </React.Suspense>
    );
}
