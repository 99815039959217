import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopBar } from '@weezevent/nacre';
import { useAccount, useAccountControls } from '@weezevent/connect';

export default function Account() {
    const { t } = useTranslation();
    const { connect } = window.weezConfig;
    const { name, email } = useAccount();
    const controls = useAccountControls();

    const handleAccountsRedirect = React.useCallback(() => {
        if (controls.manageAccount !== undefined) {
            return controls.manageAccount();
        }
        const accountsBaseUrl = connect.oAuthUrl.replace('/auth', '');
        const clientId = connect.clientId;
        const { redirect_uri } = window.weezConfig.connect;
        return window.location.assign(`${accountsBaseUrl}/settings/account?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirect_uri)}`);
    }, [connect, controls]);

    const handleLogout = React.useCallback(() => {
        return controls.logout();
    }, [controls]);

    return (
        <TopBar.Account
            tooltipTitle={t('sauron.top_bar.account')}
            content={name}
            items={[
                <TopBar.Account.Item
                    key="account"
                    title={name}
                    subtitle={t('sauron.top_bar.account')}
                    primary={{
                        onClick: handleAccountsRedirect,
                        label: t('sauron.top_bar.manage_profil')
                    }}
                    mail={email}
                    secondary={{
                        onClick: handleLogout,
                        label: t('sauron.top_bar.logout')
                    }}
                />
            ]}
        />
    );
}
