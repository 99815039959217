import React from 'react';
import { ViewPort, MenuContainer } from '@weezevent/nacre';
import { useHistory } from 'react-router-dom';
import { WeezLogo } from '../../images';
import SideBarMenu from './sidebar/SideBarMenu';
import TopBarModules from '../topBar/topBarModules';
import ErrorBoundary from '../errorBoundary';

export const Template = ({ children }) => {
    const history = useHistory();
    return (
        <ViewPort logo={WeezLogo} topBarModules={<TopBarModules />} displayMenuButton onClickLogo={() => history.push('/organizations')}>
            <MenuContainer menu={<SideBarMenu />}>
                <ErrorBoundary location={window.location}>{children}</ErrorBoundary>
            </MenuContainer>
        </ViewPort>
    );
};
