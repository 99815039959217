import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FrameCard, EmptyState, Icon } from '@weezevent/nacre';

import { LoadingCard } from '../../../components';

export const UserHistory = ({ user, loading }) => {
    const { t } = useTranslation();
    return (
        <FrameCard id="history" separator subtitle={t('sauron.user.history')} title={t('common.history')}>
            <LoadingCard loading={loading} height="20vh">
                <EmptyState title={t('common.unavailable')} subtitle={t('common.construction-block')} icon={<Icon margin name="traffic-barrier" size="regular" />} />
            </LoadingCard>
        </FrameCard>
    );
};

UserHistory.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool
};

UserHistory.defaultProps = {
    loading: true,
    user: {}
};
