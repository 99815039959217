import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button } from '@weezevent/nacre';
import { LoadingCard } from '../../../../components';
import { EditCreateOrDuplicate, DeleteAction, EditCapacity } from './Actions';

const ACTIONS = [
    { name: 'edit', component: EditCreateOrDuplicate },
    { name: 'add', component: EditCreateOrDuplicate },
    { name: 'duplicate', component: EditCreateOrDuplicate },
    { name: 'delete', component: DeleteAction },
    { name: 'manage', component: EditCapacity }
];

class RoleAction {
    #action;
    #actions;
    #validActions;
    #component;

    constructor(action) {
        this.#action = action;
        this.#actions = ACTIONS;
        this.#component = this.#actions.find(act => act.name === this.#action);
        this.#validActions = this.#actions.map(act => act.name);
        this.#instantiate();
    }

    // TODO check for usage and validity
    #instantiate() {
        if (!RoleAction.instance) {
            RoleAction.instance = this;
        }
        return RoleAction.instance;
    }

    #validate() {
        if (!this.#component || !this.#validActions.includes(this.#action)) {
            throw new Error(`${this.#action} is not valid, please provide a valid action`);
        }
    }

    get_component() {
        this.#validate();
        return this.#component.component;
    }
}

const RoleActions = ({ datas, onClose, sync }) => {
    const { action, item, name } = datas;

    if (!action) {
        return null;
    }
    const ActionComponent = new RoleAction(action).get_component();
    return <ActionComponent action={action} item={item} name={name} onClose={onClose} sync={sync} />;
};

export const RoleActionModal = ({ action, item, name, loading, disabled, onClose, onSubmit, saveLabel, customFooter, children }) => {
    const { t } = useTranslation();
    return (
        <Modal
            size={'large'}
            open
            allowExternalClosing={false}
            onClose={onClose}
            title={t(`sauron.${name}.${action}`, { name: item?.name })}
            subtitle={t(`sauron.${name}.${action}-subtitle`)}
            cancelButton={!customFooter && <Button onClick={onClose} label={t('common.cta.cancel')} />}
            submitButton={!customFooter && <Button primary onClick={onSubmit} disabled={disabled} label={saveLabel} />}
            customFooter={customFooter}
        >
            <LoadingCard loading={loading}>{children}</LoadingCard>
        </Modal>
    );
};

RoleActionModal.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    customFooter: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    saveLabel: PropTypes.string,
    role: PropTypes.object,
    capacity: PropTypes.object,
    children: PropTypes.node.isRequired
};

RoleActionModal.defaultProps = {
    saveLabel: <Trans i18nKey="common.cta.save" />,
    disabled: false,
    loading: false,
    customFooter: false,
    onClose: () => {},
    onSubmit: () => {},
    role: {},
    capacity: {}
};

export default RoleActions;
