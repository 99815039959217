import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Initials } from '@weezevent/nacre';

import { TicketCard, LoadingCard } from '../../../components';

import css from './user.module.css';

export const UserInformations = ({ user, name, loading }) => {
    return (
        <TicketCard id="information">
            <LoadingCard loading={loading} height="20vh">
                <TicketCard.Header>
                    <div className={css['user-identity']}>
                        <Initials content={name} size="big" />
                        <span className={css['user-fullname']}>{name}</span>
                    </div>
                    <div className={css['user-details']}>
                        <UserDetails user={user} field="email" />
                    </div>
                </TicketCard.Header>
                <TicketCard.Footer />
            </LoadingCard>
        </TicketCard>
    );
};

UserInformations.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
    loading: PropTypes.bool
};

UserInformations.defaultProps = {
    name: '-',
    loading: true,
    user: {}
};

const UserDetails = ({ user, field }) => {
    const { t } = useTranslation();
    return (
        <span className={css['user-detail']}>
            <span className={css['user-detail-label']}>{t(`common.${field}`)}</span>
            <span className={css['user-detail-content']}>{user?.[field] || '-'}</span>
        </span>
    );
};

UserDetails.propTypes = {
    user: PropTypes.object,
    field: PropTypes.string
};

UserDetails.defaultProps = {
    user: {},
    field: ''
};
