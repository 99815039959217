import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    WeezHeader,
    Icon,
    Card,
    Button,
    ContentCard,
    FilterSetController,
    Table,
    Toggle,
    TableCellDropdown,
    ButtonDropdown,
    Dropdown,
    ToastContext,
    SelectButton,
    DropdownSort,
    DropdownItem
} from '@weezevent/nacre';

import { Role, Capacity } from '../../../models';
import { resolve, listSelection, uniqueSelection, matchSearch } from '../../../utils/utils';
import { LoadingTable, QuickFilters } from '../../../components';
import RoleActions from './actions/ActionsHandler';

export const RoleCapacities = ({ roleId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const toast = React.useContext(ToastContext);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [ordering, setOrdering] = React.useState('alpha');
    const [filterActiveCapacities, setFilterActiveCapacities] = React.useState(false);
    const [actionDatas, setActionDatas] = React.useState({ action: null, item: null, name: null });

    const [selectedCapacities, setSelectedCapacities] = React.useState([]);
    const [activeCapacities, setActiveCapacities] = React.useState([]);

    const [role, loadingRole, syncRole] = Role.useApiModel({
        id: roleId,
        cache: false,
        allow_cache: false,
        onSuccess: response => {
            setActiveCapacities(response?.capacities.map(cap => cap.slug));
            setSelectedCapacities([]);
        },
        onError: () => {
            toast.error(t('sauron.toasts.error'));
        }
    });

    const [capacities, loadingCapacities, syncCapacities] = Capacity.useApiModel({
        cache: false,
        allow_cache: false,
        onError: () => {
            toast.error(t('sauron.toasts.error'));
        }
    });

    const capacitiesList = React.useMemo(() => {
        if (!capacities || loadingCapacities) {
            return [];
        }
        let _capacities = [...capacities];

        switch (ordering) {
            case 'alpha':
            default:
                _capacities.sort((a, b) => a.slug?.localeCompare(b?.slug));
                break;
            case '-alpha':
                _capacities.sort((b, a) => a.slug?.localeCompare(b?.slug));
                break;
        }

        if (filterActiveCapacities) {
            _capacities = _capacities.filter(cap => activeCapacities.includes(cap.slug));
        }

        return _capacities.filter(cap => matchSearch([cap.name, cap.slug], search));
    }, [capacities, activeCapacities, loadingCapacities, search, ordering, filterActiveCapacities]);

    const capacitiesIds = React.useMemo(() => {
        return capacitiesList.map(cap => cap.slug);
    }, [capacitiesList]);

    const handleSelect = React.useCallback(
        capacity => {
            setSelectedCapacities(listSelection(selectedCapacities, capacitiesIds, capacity));
        },
        [selectedCapacities, capacitiesIds, setSelectedCapacities]
    );

    const handleToggle = React.useCallback(
        (capacity, checked) => {
            setLoading(true);
            let _role = role;
            // Trigger patch method
            _role.id = roleId;

            const role_capacities = uniqueSelection(activeCapacities, capacity.slug);
            _role.capacities = capacities?.filter(cap => role_capacities.includes(cap.slug)).map(cap => ({ name: cap.name, slug: cap.slug }));

            role.save()
                .then(response => {
                    if (response.status === 200) {
                        toast.success(t(`sauron.capacity.toast-activate-${checked}`));
                    }
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    syncRole();
                    setLoading(false);
                });
        },
        [role, roleId, capacities, activeCapacities, syncRole]
    );

    const handleBulkActions = React.useCallback(
        activate => {
            setLoading(true);
            let _role = role;
            // Trigger patch method
            _role.id = roleId;
            let new_capacities = [];

            if (activate) {
                new_capacities = Array.from(new Set([...selectedCapacities, ...activeCapacities]));
            } else {
                new_capacities = activeCapacities.filter(cap => !selectedCapacities.includes(cap));
            }

            const role_capacities = capacities.filter(cap => new_capacities.includes(cap.slug)).map(cap => ({ name: cap.name, slug: cap.slug }));
            _role.capacities = role_capacities;
            _role
                .save()
                .then(response => {
                    if (response.status === 200) {
                        toast.success(t(`sauron.capacities.toast-activate-${activate}`));
                    }
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    syncRole();
                    setLoading(false);
                });
        },
        [selectedCapacities, activeCapacities, capacities, role, roleId]
    );

    return (
        <>
            <WeezHeader
                title={role?.name || ' '}
                backLink={{
                    onClick: () => {
                        history.push(`/roles`);
                    }
                }}
                rightComponent={[
                    <ButtonDropdown
                        key="dropdown"
                        trigger={<Button inverted primary icon={<Icon name="chevron-bottom" />} label={t('common.actions')} />}
                        items={[<Dropdown.Item key={0} item={t('sauron.roles.edit')} onClick={() => setActionDatas({ action: 'edit', item: role, name: 'roles' })} />]}
                    />
                ]}
            />

            <Card>
                <ContentCard>
                    <FilterSetController
                        total={activeCapacities.length}
                        labelTotal={t('sauron.roles.capacities', { count: activeCapacities.length })}
                        onChange={() => {}}
                        search={{
                            onChange: (_, value) => {
                                setSearch(value);
                            },
                            handleReset: () => {
                                setSearch('');
                            },
                            placeholder: t('common.search'),
                            value: search
                        }}
                    />
                    <SelectButton
                        tooltipTitle={t('sauron.roles.no_action_tooltip')}
                        title={selectedCapacities.length > 0 ? t('sauron.capacities.capacity_action', { count: selectedCapacities.length }) : t('common.action')}
                        objects={selectedCapacities}
                    >
                        <Dropdown.Item item={t('sauron.capacities.activate')} onClick={() => handleBulkActions(true)} />
                        <Dropdown.Item item={t('sauron.capacities.deactivate')} onClick={() => handleBulkActions(false)} />
                    </SelectButton>
                    <QuickFilters>
                        <QuickFilters.Item label={t('sauron.capacities.quick-filter-label')} active={filterActiveCapacities} onToggle={setFilterActiveCapacities} />
                    </QuickFilters>
                </ContentCard>
                <RoleActions
                    datas={actionDatas}
                    onClose={() => setActionDatas({ action: null, item: null, name: null })}
                    sync={() => {
                        syncCapacities();
                        syncRole();
                    }}
                />

                <Table useNew selectable onSelectAll={() => handleSelect()}>
                    <Table.THead>
                        <Table.Tr isHeader active={capacitiesIds.length > 0 && capacitiesIds.every(id => selectedCapacities.includes(id))}>
                            <th>
                                <DropdownSort
                                    active
                                    name="alpha"
                                    ordering={ordering}
                                    label={t(`common.capacity`)}
                                    items={[
                                        <DropdownItem.Icons key={0} item={t('common.ordering.alphabetical')} checked={ordering === 'alpha'} onClick={() => setOrdering('alpha')} />,
                                        <DropdownItem.Icons
                                            key={1}
                                            item={t('common.ordering.-alphabetical')}
                                            checked={ordering === '-alpha'}
                                            onClick={() => setOrdering('-alpha')}
                                        />
                                    ]}
                                />
                            </th>
                            <th>{t(`common.activation`)}</th>
                            <th>{t(`common.actions`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!(loadingRole || loadingCapacities || loading) &&
                            capacitiesList.map(capacity => (
                                <CapacityRow
                                    key={capacity.slug}
                                    capacities={capacities}
                                    capacity={capacity}
                                    roleCapacities={activeCapacities}
                                    selected={selectedCapacities}
                                    onSelect={handleSelect}
                                    onToggle={handleToggle}
                                    setAction={setActionDatas}
                                />
                            ))}
                    </Table.TBody>
                </Table>

                <LoadingTable loading={loading || loadingRole || loadingCapacities} height="30vh" />
            </Card>
        </>
    );
};

const CapacityRow = ({ roleCapacities, capacity, selected, onSelect, onToggle, setAction }) => {
    const { t } = useTranslation();
    return (
        <Table.Tr onSelected={() => onSelect(capacity.slug)} active={selected.includes(capacity.slug)}>
            <Table.Td>{resolve(capacity.name, capacity.slug)}</Table.Td>
            <Table.Td>
                <Toggle mini checked={roleCapacities.includes(capacity.slug)} onChange={({ target: { checked } }) => onToggle(capacity, checked)} />
            </Table.Td>
            <TableCellDropdown>
                <Dropdown.Item item={t('sauron.capacities.edit')} onClick={() => setAction({ action: 'manage', name: 'capacity', item: capacity })} />
            </TableCellDropdown>
        </Table.Tr>
    );
};
