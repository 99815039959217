import { BaseAPIModel } from '@weezevent/weezjs-core';

const aliases = {
    'FR-fr': 'fr-FR',
    fr: 'fr-FR',
    en: 'en-GB',
    es: 'es-ES',
    it: 'it-IT'
};

export class Organization extends BaseAPIModel {
    fields() {
        return {
            name: {},
            id: { primaryKey: true, readOnly: true }
        };
    }

    defaultLanguage() {
        const orgaLocale = this.default_locale?.replace('_', '-') || 'en-GB';
        const locale = aliases[orgaLocale] ?? orgaLocale;
        return locale;
    }

    has_feature(key) {
        return this.feature_flags && this.feature_flags.include(key);
    }

    toOption() {
        return {
            text: this.name ? `O${this.id} - ${this.name}` : `O${this.id}`,
            value: this.id,
            key: this.id
        };
    }

    static listUrl() {
        return `/ticket/organizations`;
    }
}
