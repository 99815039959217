import React from 'react';
import PropTypes from 'prop-types';
import i18n, { aliases } from '../../i18n/i18n';
import { fetcher } from '@weezevent/weezjs-core';
import { Connect, Token, RedirectDriver, TokenContext, EngineContext, useAccount } from '@weezevent/connect';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { Loading } from '../../components';

const Context = React.createContext();
const FetchContext = React.createContext();

export const useFetch = () => React.useContext(FetchContext);
export const useUser = () => React.useContext(Context);

const ConnectCompat = ({ children }) => {
    const auth = useAuth();
    const token = React.useMemo(() => auth.user && new Token(auth.user), [auth]);

    const engine = {
        logout: () => auth.removeUser(),
        manageAccount: () => {
            const clientId = window.weezConfig.connect.clientId;
            window.location.assign(window.weezConfig.connect.OIDCUrl + '/account/?referrer=' + clientId + '&referrer_uri=' + encodeURI(window.location));
        }
    };

    if (auth.isLoading || auth.error) {
        return <Loading active />;
    }

    if (auth.isAuthenticated) {
        fetcher.setAuthorizationHeader(`${auth.user.token_type} ${auth.user.access_token}`);
        const fetch = (url, opts) => {
            opts = opts || {};
            const headers = (opts.headers = opts.headers || {});
            headers.Authorization = `${auth.user.token_type} ${auth.user.access_token}`;
            return window.fetch(url, opts);
        };
        return (
            <EngineContext.Provider value={engine}>
                <TokenContext.Provider value={token}>
                    <FetchContext.Provider value={fetch}>{children}</FetchContext.Provider>
                </TokenContext.Provider>
            </EngineContext.Provider>
        );
    }
    return auth.signinRedirect();
};

const Fetcher = ({ children }) => {
    const userLocale = useAccount().locale || 'en';
    const accountsTokenLocale = userLocale.replace(/_/g, '-');
    const locale = aliases[accountsTokenLocale] ?? accountsTokenLocale;
    i18n.accountLocale = accountsTokenLocale;

    if (locale !== i18n.language) {
        i18n.changeLanguage(locale);
    }

    return children;
};

export const Authentication = ({ children, redirectPath, config }) => {
    const onSigninCallback = user => {
        window.history.replaceState({}, document.title, window.location.pathname);
    };

    if (config.OIDCUrl) {
        return (
            <AuthProvider automaticSilentRenew authority={config.OIDCUrl} redirect_uri={String(window.location)} onSigninCallback={onSigninCallback} client_id={config.clientId}>
                <ConnectCompat>{children}</ConnectCompat>
            </AuthProvider>
        );
    }

    return (
        <Connect redirectPath="/" config={config} driver={new RedirectDriver()}>
            <Fetcher>{children}</Fetcher>
        </Connect>
    );
};

Authentication.propTypes = {
    children: PropTypes.node.isRequired,
    redirectPath: PropTypes.string.isRequired,
    config: PropTypes.shape({
        OIDCUrl: PropTypes.string,
        clientId: PropTypes.string
    }).isRequired
};
