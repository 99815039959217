import React from 'react';
import PropTypes from 'prop-types';

import css from './ticketCard.module.css';

function TicketCardHeader({ children = null, className = '' }) {
    return <div className={`${css['ticket-card-header']} ${className}`}>{children}</div>;
}

TicketCardHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

function TicketCardContent({ children = null, className = '', ticket = false }) {
    return <div className={`${css['ticket-card-content']} ${ticket ? css['ticket-card-content-ticket'] : ''} ${className}`}>{children}</div>;
}

TicketCardContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    ticket: PropTypes.bool
};

function TicketCardFooter({ children = null, className = '' }) {
    return <div className={`${css['ticket-card-footer']} ${className}`}>{children}</div>;
}

TicketCardFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export default function TicketCard({ children = null, className = '', ...rest }) {
    return (
        <div className={`${css['ticket-card']} ${className}`} {...rest}>
            {children}
        </div>
    );
}

TicketCard.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

TicketCard.Header = TicketCardHeader;
TicketCard.Content = TicketCardContent;
TicketCard.Footer = TicketCardFooter;
