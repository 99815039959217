import React from 'react';
import PropTypes from 'prop-types';
import AppContext from './appContext';

export default function AppContextProvider({ children }) {
    const [context, _setContext] = React.useState({});

    const setContext = React.useCallback((ctx = {}) => {
        _setContext(context => {
            return { ...context, ...ctx };
        });
    }, []);

    const removeFromContext = React.useCallback(name => {
        _setContext(context => {
            let newContext = { ...context };
            delete newContext[name];
            return newContext;
        });
    }, []);

    return (
        <AppContext.Provider
            value={{
                context,
                setContext,
                removeFromContext
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

AppContextProvider.propTypes = {
    children: PropTypes.node
};
