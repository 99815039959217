import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function LoadingTable({ loading = false, marginTop = '60px', height = '60vh' }) {
    const { t } = useTranslation();

    if (!loading) {
        return null;
    }
    return (
        <div style={{ height: height }}>
            <Loader active style={{ zIndex: '3', marginTop: marginTop }}>
                {t('common.loading')}
            </Loader>
        </div>
    );
}

LoadingTable.propTypes = {
    loading: PropTypes.bool
};
