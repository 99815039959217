import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FrameCard, Button, Icon, Table, DropdownItem, DropdownSort, Tooltip } from '@weezevent/nacre';

import { LoadingCard, InfoTextTooltip } from '../../../components';

import css from './user.module.css';
import { usePermissions } from '../../../models/user/permissions';

export const UserRoles = ({ user, loading, onToggle, handleDelete }) => {
    const { t } = useTranslation();
    const authenticatedUser = usePermissions();
    const [ordering, setOrdering] = React.useState('alpha');
    const isRoleAdmin = authenticatedUser.canManageRoles();

    const roles = React.useMemo(() => {
        if (!user || loading || !user?.user_roles?.length) {
            return [];
        }
        switch (ordering) {
            case 'alpha':
                return user.user_roles.sort((a, b) => a.role.name?.localeCompare(b?.role.name));
            case '-alpha':
                return user.user_roles.sort((b, a) => a.role.name?.localeCompare(b?.role.name));
            default:
                return user.user_roles;
        }
    }, [user, loading, ordering]);

    return (
        <FrameCard
            id="roles"
            separator
            subtitle={t('sauron.user.roles')}
            title={
                <span className={css['user-roles-header']}>
                    {t('common.roles')}
                    <Button basic label={t('sauron.user.add-role')} onClick={onToggle} disabled={!isRoleAdmin} icon={<Icon name="plus" size="regular" />} />
                </span>
            }
        >
            <LoadingCard loading={loading} height="20vh">
                <Table useNew className={css['user-roles-table']}>
                    <Table.THead>
                        <Table.Tr isHeader>
                            <th className={css['user-roles-table-medium']}>
                                <DropdownSort
                                    active
                                    name="alpha"
                                    ordering={ordering}
                                    label={t(`common.role`)}
                                    items={[
                                        <DropdownItem.Icons key={1} item={t('common.ordering.alphabetical')} checked={ordering === 'alpha'} onClick={() => setOrdering('alpha')} />,
                                        <DropdownItem.Icons
                                            key={2}
                                            item={t('common.ordering.-alphabetical')}
                                            checked={ordering === '-alpha'}
                                            onClick={() => setOrdering('-alpha')}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['user-roles-table-large']}>{t(`common.context`)}</th>
                            {isRoleAdmin && <th className={css['user-roles-table-small']}>{t(`common.actions`)}</th>}
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loading &&
                            roles.length > 0 &&
                            roles?.filter(role => role.context.level <= 3).map((role, i) => <RoleItem key={i} role={role} handleDelete={handleDelete} isRoleAdmin={isRoleAdmin} />)}
                    </Table.TBody>
                </Table>
            </LoadingCard>
        </FrameCard>
    );
};

UserRoles.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    onToggle: PropTypes.func
};

UserRoles.defaultProps = {
    loading: true,
    user: {},
    onToggle: () => {}
};

const RoleItem = ({ role, handleDelete, isRoleAdmin }) => {
    const { t } = useTranslation();
    return (
        <Table.Tr>
            <Table.Td>
                <span>{role.role.name}</span>
            </Table.Td>
            <Table.Td>
                <InfoTextTooltip title={role.context.value ? `O${role.context.value}` : '-'} />
            </Table.Td>
            {isRoleAdmin && (
                <Table.Td>
                    <Tooltip title={t('sauron.user.delete-role')} triggerOnHover visible>
                        <Icon name="trash" onClick={() => handleDelete(role)} />
                    </Tooltip>
                </Table.Td>
            )}
        </Table.Tr>
    );
};
